import React, { useMemo } from "react";
import Icon from "../../Icon/Icon";
import styles from "./ChampionHeroBackButton.module.scss";
import { ButtonColorTheme, ButtonColorThemeOption } from "../../../../data/enum/ColorTheme";
import { Link } from "gatsby-plugin-intl";
import { ContentstackAsset } from "../../../../data/types/Contentstack";

export interface ButtonProps {
  className?: string;
  theme?: ButtonColorThemeOption;
  innerRef?: React.MutableRefObject<HTMLAnchorElement | null>;
}

export interface ChampionHeroBackButtonProps extends ButtonProps {
  imageIcon: ContentstackAsset;
}

export const ChampionHeroBackButton: React.FC<ChampionHeroBackButtonProps> = ({
  className = "",
  theme = ButtonColorTheme.DEFAULT,
  imageIcon,
  innerRef = null,
}) => {
  const classes = useMemo(() => `button ${className} ${styles.championHeroBackButton} ${styles[theme]}`, [
    className,
    theme,
  ]);

  return (
    <Link innerRef={innerRef} className={classes} type="button" to="/champions/">
      <div className={styles.buttonContent}>
        <Icon className={styles.icon} name="Chevron" />
        <img className={styles.imageIcon} src={imageIcon.url} />
      </div>
    </Link>
  );
};
