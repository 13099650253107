import { graphql, PageProps } from "gatsby";
import React from "react";
import { ChampionAbilities } from "../components/block/ChampionAbilities/ChampionAbilities";
import { ChampionDetailHero } from "../components/block/ChampionDetailHero/ChampionDetailHero";
import { SkinsSection } from "../components/block/SkinsSection/SkinsSection";
import { RelatedContent } from "../components/content/RelatedContent/RelatedContent";
import { ColorTheme } from "../data/enum/ColorTheme";
import {
  ContentstackArticle,
  ContentstackArticlesGlobal,
  ContentstackChampionDetailGlobal,
} from "../data/types/Contentstack";
import { ContentstackChampionDetail } from "../data/types/ContentstackPages";
import styles from "../style/templates/ChampionDetail.module.scss";

interface ChampionDetailProps {
  allContentstackChampionDetail: {
    nodes: Array<ContentstackChampionDetail>;
  };
  allContentstackArticles: {
    articles: Array<ContentstackArticle>;
  };
  contentstackChampionDetailGlobal: {
    championDetailGlobal: ContentstackChampionDetailGlobal;
    articlesGlobal: ContentstackArticlesGlobal;
  };
  contentstackArticlesGlobal: ContentstackArticlesGlobal;
}

interface ChampionDetailProps2 {
  data?: {
    result?: {
      data?: {
        allContentstackChampionDetail: {
          nodes: Array<ContentstackChampionDetail>;
        };
        allContentstackArticles: {
          articles: Array<ContentstackArticle>;
        };
        contentstackChampionDetailGlobal: {
          championDetailGlobal: ContentstackChampionDetailGlobal;
          articlesGlobal: ContentstackArticlesGlobal;
        };
        contentstackArticlesGlobal: ContentstackArticlesGlobal;
      }
    }
  }
}
const ChampionDetail: React.FC<PageProps<ChampionDetailProps>> = ({
  pageContext,
}) => {
  let pageContextObj: ChampionDetailProps2 = pageContext;
  const championData = pageContextObj.data?.result?.data;
  const node = championData?.allContentstackChampionDetail?.nodes[0]
  const championDetail = championData?.contentstackChampionDetailGlobal?.championDetailGlobal;
  const articlesGlobal = championData?.contentstackChampionDetailGlobal?.articlesGlobal;
  const allContentstackArticles = championData?.allContentstackArticles;

  return (
    <>
      <ChampionDetailHero
        hero={node!.hero}
        heroImage={node!.heroImage}
        heroVideo={node!.heroVideo}
        backButtonIcon={championDetail!.backButtonIcon}
        title={node!.title}
        subtitle={node!.subtitle}
        roles={node!.roles}
        roleCopy={championDetail!.roleCopy}
        difficulty={node!.difficulty}
        difficultyCopy={championDetail!.difficultyCopy}
        difficultyLevel={node!.difficultyLevel}
      />
      <ChampionAbilities
        titleParts={[championDetail!.abilitiesCopy]}
        detailLineVariation="top"
        abilities={node!.abilities}
        theme={ColorTheme.DARK}
      />
      <div className={styles.sectionsWrapper}>
        <SkinsSection titleParts={championDetail!.skinsCopy} buttons={node!.buttons} skins={node!.skins} />
        <RelatedContent
          relatedTags={node!.relatedTags}
          allArticles={allContentstackArticles!.articles}
          promoAd={node!.promoAd}
          titleParts={articlesGlobal!.relatedArticlesCopyParts}
        />
      </div>
    </>
  )
};

export const query = graphql`
  query ChampionDetailQuery {
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default ChampionDetail;
