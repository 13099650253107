import React, { useEffect, useState } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackArticle, ContentstackPromoSection } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { PromoAd } from "../../block/PromoAd/PromoAd";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { UnderlineSlider } from "../slider/UnderlineSlider/UnderlineSlider";
import styles from "./RelatedContent.module.scss";

export interface RelatedContentProps {
  relatedTags: Array<{ title: string }>;
  titleParts: Array<string>;
  allArticles: Array<ContentstackArticle>;
  promoAd: ContentstackPromoSection;
}

export const RelatedContent: React.FC<RelatedContentProps> = ({ relatedTags, titleParts, allArticles, promoAd }) => {
  const [relatedContent, setRelatedContent] = useState<Array<ContentstackArticle>>([]);
  const oneLineTitle = titleParts.join(" ");
  const isMobile = useIsMobile();

  useEffect(() => {
    const allRelatedTags = relatedTags.map((tag) => tag.title);

    const allArticlesTags = allArticles.map((article) => article.tags.map((tag) => tag.title));
    const matchingArticles = allArticlesTags.map((articles) => allRelatedTags.some((tag) => articles.includes(tag)));

    const filteredArticles = allArticles.filter((_, index) => matchingArticles[index]).slice(0, 5);

    setRelatedContent(filteredArticles);
  }, [relatedTags, allArticles]);

  return relatedContent.length || promoAd.isEnabled ? (
    <section className={`${styles.relatedContent} ${promoAd.isEnabled && !relatedContent.length ? styles.onlyAd : ""}`}>
      <span className={styles.background}></span>
      {promoAd.isEnabled &&
        promoAd.selectedPromos.map((promo) => (
          <div key={promo.title} className={`container`}>
            <div className={`content-wrapper`}>
              <PromoAd selectedPromo={promo} />
            </div>
          </div>
        ))}
      {relatedContent.length ? (
        <>
          <div className={`container`}>
            <div className={`content-wrapper ${styles.header}`}>
              <DetailLine className={styles.copyWrapper} variation="halfTop" theme={ColorTheme.LIGHT}>
                <SectionTitle
                  className={styles.title}
                  titleParts={isMobile ? titleParts : [oneLineTitle]}
                  theme={ColorTheme.LIGHT}
                />
              </DetailLine>
            </div>
          </div>
          <UnderlineSlider slides={relatedContent} />
        </>
      ) : null}
    </section>
  ) : null;
};
