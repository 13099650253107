import { graphql } from "gatsby";
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackChampionSkin, ContentstackCTA } from "../../../data/types/Contentstack";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import { TwoSideBorder } from "../../general/borders/TwoSideBorder/TwoSideBorder";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { Thumbnail } from "../../general/Thumbnail/Thumbnail";
import styles from "./SkinsSection.module.scss";

export interface SkinsSectionProps {
  titleParts: Array<string>;
  skins: Array<ContentstackChampionSkin>;
  buttons: Array<ContentstackCTA>;
}

export const SkinsSection: React.FC<SkinsSectionProps> = ({ titleParts, skins, buttons }) => {
  const [activeSkinIndex, setActiveSkinIndex] = useState(0);
  const skinsWrapperRef = useRef<HTMLDivElement | null>(null);
  const transitionSkin = () => {
    if (skinsWrapperRef.current) {
      gsap.set(skinsWrapperRef.current.children[1], {
        autoAlpha: 1,
      });
    }
  };
  useEffect(() => {
    transitionSkin();
  }, [activeSkinIndex]);

  return (
    <section className={` ${styles.skinSection}`}>
      <div className={`container  ${styles.header}`}>
        <div className={`content-wrapper`}>
          <DetailLine className={styles.copyWrapper} variation="halfTop" theme={ColorTheme.LIGHT}>
            <SectionTitle className={`heading-08 ${styles.title}`} titleParts={titleParts} theme={ColorTheme.LIGHT} />
          </DetailLine>
        </div>
      </div>
      <div className={styles.skinsGallery}>
        <div ref={skinsWrapperRef} className={styles.imageWrapper}>
          <TwoSideBorder className={`${styles.imageBorderLeft} ${styles.bottom}`} position="bottomLeft" />
          <img src={skins[activeSkinIndex].splash.url} className={styles.skinImage} />
        </div>
        <ul className={styles.skinsList}>
          {skins.map((skin, index) => (
            <Thumbnail
              key={index}
              thumbnail={skin.icon}
              index={index}
              caption={`${index + 1}/${skins.length} · ${skin.name}`}
              setActiveItem={setActiveSkinIndex}
              className={`${styles.thumbnail} ${activeSkinIndex === index ? styles.isActive : ""}`}
              isSkinsSection={true}
            />
          ))}
        </ul>
      </div>

      <footer className={styles.sectionFooter}>
        {buttons.map((button, index) => (
          <div key={index}>
            <PrimaryButton
              label={button.title}
              href={button.href}
              theme={index === 1 ? ColorTheme.LIGHT : ColorTheme.DARK}
            />
          </div>
        ))}
      </footer>
    </section>
  );
};

export const query = graphql`
  fragment SkinsSection on Contentstack_champions {
    skins: champion_skins {
      name: skin_name
      splash: skin_splash {
        ...ContentstackAsset
      }
      icon: skin_icon {
        ...ContentstackAsset
      }
    }
    buttons {
      title
      href
    }
  }
`;
