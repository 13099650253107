import React, { useContext, useEffect, useRef } from "react";
import styles from "./ChampionDetailHero.module.scss";
import Locale from "../../../data/contexts/riot-locale";
import { isRTL } from "../../../utils/isRTL";
import { isIE } from "../../../utils/browser-detect";
import { ChampionHeroBackButton } from "../../general/button/ChampionHeroBackButton/ChampionHeroBackButton";
import {
  ChampionDifficultyLevel,
  ContentstackAsset,
  ContentstackChampionRole,
  ContentstackVideo,
} from "../../../data/types/Contentstack";
import { Video } from "../../general/Video/Video";
import { graphql } from "gatsby";
import { useInView } from "react-intersection-observer";
import { animateBackToButton, animateChampionDetail } from "./animations";

export interface ChampionDetailHeroProps {
  hero: "HTML5 video" | "Image";
  heroImage?: ContentstackAsset;
  heroVideo?: Array<ContentstackVideo>;
  heroVideoMobile?: Array<ContentstackVideo>;
  backButtonIcon: ContentstackAsset;
  title: string;
  subtitle: string;
  roles: Array<ContentstackChampionRole>;
  roleCopy: string;
  difficulty: string;
  difficultyCopy: string;
  difficultyLevel: ChampionDifficultyLevel;
}

export const ChampionDetailHero: React.FC<ChampionDetailHeroProps> = ({
  hero,
  heroImage,
  heroVideo,
  heroVideoMobile,
  backButtonIcon,
  title,
  subtitle,
  roles,
  roleCopy,
  difficulty,
  difficultyCopy,
  difficultyLevel,
}) => {
  const [observerRef, inView] = useInView({ triggerOnce: true });
  const backButtonRef = useRef<HTMLAnchorElement | null>(null);
  const championDetailWrapperRef = useRef<HTMLDivElement | null>(null);

  const locale = useContext(Locale);
  const isRtl = isRTL(locale);

  useEffect(() => {
    if (inView) {
      animateBackToButton(backButtonRef.current, isRtl);
      animateChampionDetail(championDetailWrapperRef.current);
    }
  }, [inView, isRtl]);
  const imageHero = heroImage && (
    <>
      {isIE() ? (
        <div className={styles.ieBackgroundImage} style={{ backgroundImage: `url(${heroImage.url})` }} />
      ) : (
        <picture className={styles.backgroundImageWrapper}>
          <img src={heroImage.url} className={styles.backgroundImage} />
        </picture>
      )}
    </>
  );

  const videoHero = heroVideo?.[0] && (
    <Video
      className={styles.heroVideo}
      source={{ mp4Url: heroVideo[0].video.file.url, posterUrl: heroVideo[0].posterImage.url }}
      mobileSource={{
        mp4Url: heroVideoMobile ? heroVideoMobile[0].video.file.url : heroVideo[0].video.file.url,
        posterUrl: heroVideoMobile ? heroVideoMobile[0].posterImage.url : heroVideo[0].posterImage.url,
      }}
    />
  );

  return (
    <section className={styles.championDetailHero} ref={observerRef}>
      <ChampionHeroBackButton innerRef={backButtonRef} className={` ${styles.backButton}`} imageIcon={backButtonIcon} />
      <div className={styles.heroMediaWrapper}>{hero === "HTML5 video" ? videoHero : imageHero}</div>
      <div className={`container ${styles.heroContentWrapper}`}>
        <div className={`content-wrapper ${styles.contentWrapper}`} ref={championDetailWrapperRef}>
          <span className={styles.dashDetail} />
          <div className={styles.heroContent}>
            <div className={styles.descriptionWrapper}>
              <h3 className={`heading-08 ${styles.championName}`}>{title}</h3>
              <p className={`copy-01 ${styles.championSubtitle}`}>{subtitle}</p>
            </div>
            <div className={styles.detailsWrapper}>
              <div className={styles.roleWrapper}>
                <div className={styles.iconWrapper}>
                  <img className={styles.roleIcon} src={roles[0].icon.url} />
                  {roles[1] && <img className={styles.roleIcon} src={roles[1].icon.url} />}
                </div>
                <div className={styles.copyWrapper}>
                  <span className={`label-05 ${styles.roleTitle}`}>{`${roleCopy}`}</span>
                  <span className={`label-05 ${styles.roleName}`}>{`${roles[0].name}${
                    roles[1] ? ` / ${roles[1].name}` : ""
                  }`}</span>
                </div>
              </div>
              <div className={styles.difficultyWrapper}>
                <div className={`${styles.difficultyBars}`} data-difficulty={`${difficultyLevel}`}>
                  <span className={styles.bar}>
                    <span className={styles.barInner}></span>
                  </span>
                  <span className={styles.bar}>
                    <span className={styles.barInner}></span>
                  </span>
                  <span className={styles.bar}>
                    <span className={styles.barInner}></span>
                  </span>
                </div>
                <div className={styles.copyWrapper}>
                  <span className={`label-05 ${styles.difficultyTitle}`}>{difficultyCopy}</span>
                  <span className={`label-05 ${styles.difficultyName}`}>{difficulty}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ChampionDetailHero on Contentstack_champions {
    title
    hero: champion_hero
    heroImage: champion_splash {
      ...ContentstackAsset
    }
    heroVideo: champion_hero_video {
      ...ContentstackVideo
    }
    heroVideoMobile: champion_hero_video_mobile {
      ...ContentstackVideo
    }
    subtitle: champion_quote
    roles: champion_role {
      ...ContentstackChampionRole
    }
    difficulty
    difficultyLevel: difficulty_level
  }
`;
