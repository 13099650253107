import React from "react";
import { graphql } from "gatsby";
import { ContentstackPromo } from "../../../data/types/Contentstack";
import styles from "./PromoAd.module.scss";

export interface PromoAdProps {
  selectedPromo: ContentstackPromo;
}

export const PromoAd: React.FC<PromoAdProps> = ({
  selectedPromo: {
    title,
    image: { url: imageUrl },
    linkUrl,
  },
}) => (
  <div className={styles.promoAdWrapper}>
    <a href={linkUrl} className={styles.promoAd} target="_blank" rel="noopener noreferrer">
      <img src={imageUrl} alt={title} />
    </a>
  </div>
);

export const query = graphql`
  fragment PromoAd on Contentstack_news {
    promoAd: promo_module {
      isEnabled: enabled
      selectedPromos: promo_select {
        title
        linkUrl: link
        image {
          url
        }
      }
    }
  }
  fragment PromoAdChampionDetails on Contentstack_champions {
    promoAd: promo_module {
      isEnabled: enabled
      selectedPromos: promo_select {
        title
        linkUrl: link
        image {
          url
        }
      }
    }
  }
`;
