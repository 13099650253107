import { gsap } from "gsap";

export const animateBackToButton = (element: HTMLAnchorElement | null, isRtl: boolean) => {
  gsap.set(element, { css: { visibility: "visible" } });
  gsap.from(element, { xPercent: isRtl ? 100 : -100, duration: 1, ease: "power1.out", delay: 2 });
};

const animateDescription = (container: HTMLDivElement | null) => {
  const tl = gsap.timeline();
  const descriptionWrapperElement = container?.lastChild?.firstChild as HTMLDivElement;
  const childElements = [...descriptionWrapperElement.children];

  tl.from(childElements, { yPercent: 10, autoAlpha: 0, stagger: 0.1, duration: 0.5, ease: "expo.out" });
  return tl;
};

const animateRole = (container: HTMLDivElement | null) => {
  const tl = gsap.timeline();
  const roleImageElement = container?.lastChild?.lastChild?.firstChild?.firstChild as HTMLImageElement;
  const copyWrapperElement = container?.lastChild?.lastChild?.firstChild?.lastChild as HTMLDivElement;
  const copyElements = [...copyWrapperElement.children];

  tl.from([roleImageElement, copyElements], {
    yPercent: 10,
    autoAlpha: 0,
    stagger: 0.1,
    duration: 0.5,
    ease: "expo.out",
  });
  return tl;
};

const animateDifficulty = (container: HTMLDivElement | null) => {
  const tl = gsap.timeline();
  const difficultyBarElement = container?.lastChild?.lastChild?.lastChild?.firstChild as HTMLDivElement;
  const barElements = [...difficultyBarElement.children].map((el) => el.firstChild);
  const copyWrapperElement = container?.lastChild?.lastChild?.lastChild?.lastChild as HTMLDivElement;
  const copyElements = [...copyWrapperElement.children];

  tl.from([difficultyBarElement, copyElements], {
    yPercent: 10,
    autoAlpha: 0,
    stagger: 0.1,
    duration: 0.5,
    ease: "expo.out",
  }).from(barElements, { autoAlpha: 0, scale: 0, stagger: 0.15, duration: 0.5, ease: "elastic.out" }, "-=0.2");
  return tl;
};
export const animateChampionDetail = (container: HTMLDivElement | null) => {
  const tl = gsap.timeline();
  const dashDetailElement = container?.firstChild as HTMLSpanElement;
  tl.set(container, { css: { visibility: "visible" } })
    .from(container, { yPercent: 10, autoAlpha: 0, duration: 0.4, ease: "power1.out" }, 0.4)
    .from(dashDetailElement, { scaleX: 0, duration: 0.4, ease: "power1.out" }, "-=0.25")
    .add(animateDescription(container))
    .add(animateRole(container), "-=0.4")
    .add(animateDifficulty(container), "-=0.4");
};
