import React, { useContext, useMemo } from "react";
import styles from "./ArticleCard.module.scss";
import { CardLink } from "../../../general/CardLink/CardLink";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { localizeDateString } from "../../../../utils/date";
import Locale from "../../../../data/contexts/riot-locale";

interface ArticleCardProps {
  cardContent: ContentstackArticle;
  displayCategory: boolean;
  isActiveCard?: boolean;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
  cardContent: {
    title,
    description,
    featuredImage: {
      banner: { url: imageUrl },
    },
    youtubeLink,
    externalLink,
    link: { url: linkUrl },
    date,
    categories,
    articleType,
  },
  displayCategory,
}) => {
  const isMobile = useIsMobile();
  const locale = useContext(Locale);
  const localizedDate = useMemo(() => localizeDateString(date, locale), [date, locale]);

  return (
    <CardLink
      articleType={articleType}
      linkUrl={youtubeLink || externalLink || linkUrl}
      className={styles.articleCardWrapper}
      sliderClassName={!displayCategory ? styles.sliderCard : ""}
    >
      <span className={styles.imageWrapper}>
        <img className={styles.image} src={imageUrl} alt={title} data-type={`${articleType}`} />
        <span className={styles.articleType} data-type={`${articleType}`} />
      </span>
      {displayCategory ? (
        <>
          <span className={`copy-01`}>{localizedDate}</span>{" "}
          <span className={`copy-01 ${styles.category}`}>{categories[0].title}</span>
          <h4 className={`heading-05 font-normal ${styles.title}`}>{title} </h4>
          {description && !isMobile && <p className={`copy-02 ${styles.copy}`}>{description}</p>}
        </>
      ) : (
        <>
          {!isMobile && <span className={`copy-01`}>{localizedDate}</span>}
          <span className={styles.copyWrapper}>
            <h4 className={`heading-05 font-normal ${styles.title} ${description ? styles.halfWidth : ""}`}>
              {isMobile && description ? `${localizedDate} · ` : ""} {title}
            </h4>
            {description && !isMobile && <p className={`copy-02 ${styles.copy}`}>{description}</p>}
          </span>
        </>
      )}
    </CardLink>
  );
};
