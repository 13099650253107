import React, { FunctionComponent, useState } from "react";
import { graphql } from "gatsby";
import { ContentstackAbilityDetails } from "../../../data/types/Contentstack";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { Thumbnail } from "../../general/Thumbnail/Thumbnail";
import { Video } from "../../general/Video/Video";
import DetailLine, { DetailLineProps } from "../../general/borders/DetailLine/DetailLine";
import styles from "./ChampionAbilities.module.scss";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { TwoSideBorder } from "../../general/borders/TwoSideBorder/TwoSideBorder";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { removeTags } from "../../../utils/string";
import Icon from "../../general/Icon/Icon";

export interface ChampionAbilitiesProps {
  titleParts: Array<string>;
  abilities: Array<ContentstackAbilityDetails>;
  detailLineVariation?: DetailLineProps["variation"];
  theme?: ColorTheme;
}

export const ChampionAbilities: FunctionComponent<ChampionAbilitiesProps> = ({
  titleParts,
  abilities,
  detailLineVariation = "left",
  theme = ColorTheme.LIGHT,
}) => {
  const [activeAbilityIndex, setActiveAbilityIndex] = useState<number>(0);
  const isMobile = useIsMobile();
  return (
    <section className={`container ${styles.championAbilities}`}>
      <div className="content-wrapper">
        {isMobile && (
          <DetailLine className={styles.copyWrapper} variation={detailLineVariation} theme={theme}>
            <SectionTitle className={`heading-08 ${styles.title}`} titleParts={titleParts} theme={theme} />
          </DetailLine>
        )}
        <div className={styles.abilityContentWrapper}>
          <div className={styles.videoWrapper}>
            <TwoSideBorder
              className={`${styles.videoBorder} ${styles.top}`}
              theme={ColorTheme.ELECTRIC}
              position="topRight"
            />
            <TwoSideBorder
              className={`${styles.videoBorder} ${styles.bottom}`}
              theme={ColorTheme.ELECTRIC}
              position="bottomLeft"
            />
            {abilities[activeAbilityIndex].videos[0].video.file.url ? (
              <Video
                key={abilities[activeAbilityIndex].title}
                className={styles.videoContainer}
                source={{
                  mp4Url: abilities[activeAbilityIndex].videos[0].video.file.url,
                  posterUrl: abilities[activeAbilityIndex].videos[0].posterImage.url,
                }}
              />
            ) : (
              <h3>No video for this skill</h3>
            )}
          </div>
          <div className={styles.abilitiesWrapper}>
            {!isMobile && (
              <DetailLine className={styles.copyWrapper} variation={detailLineVariation} theme={theme}>
                <SectionTitle className={`heading-08 ${styles.title}`} titleParts={titleParts} theme={theme} />
              </DetailLine>
            )}
            <div className={styles.abilitiesDetailsWrapper}>
              <ul className={styles.thumbnailsWrapper}>
                {abilities.map(({ title, thumbnail }, index) => (
                  <Thumbnail
                    className={`${styles.thumbnail} ${activeAbilityIndex === index ? styles.isActive : ""}`}
                    key={title}
                    thumbnail={thumbnail}
                    index={index}
                    setActiveItem={setActiveAbilityIndex}
                  />
                ))}
              </ul>
              <div className={styles.detailsWrapper} key={abilities[activeAbilityIndex].title}>
                <h3 className={`heading-04 ${styles.abilityName}`}>
                  <span>{abilities[activeAbilityIndex].type}</span>
                  <Icon className={styles.textDivider} name="Diamond" />
                  <span>{abilities[activeAbilityIndex].title}</span>
                </h3>
                <p className={`copy-03 ${styles.abilityDescription}`}>
                  {removeTags(abilities[activeAbilityIndex].description)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ChampionAbilities on Contentstack_champions {
    abilities: champion_abilities {
      type: ability_type
      title: ability_name
      thumbnail: ability_icon {
        ...ContentstackAsset
      }
      description: ability_description
      videos: ability_video {
        ...ContentstackVideo
      }
    }
  }
`;
