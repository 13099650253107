import React, { useRef } from "react";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useMouseTracker } from "../../../../hooks/useMouseTracker";
// import { gsap, Power1 } from "gsap";
import { useSlider } from "../../../../hooks/useSlider";
import CustomCursor from "../../../general/CustomCursor/CustomCursor";
import Icon from "../../../general/Icon/Icon";
import { ArticleCard } from "../../cards/ArticleCard/ArticleCard";
import styles from "./UnderlineSlider.module.scss";

export interface UnderlineSliderProps {
  slides: Array<ContentstackArticle>;
}

export const UnderlineSlider: React.FC<UnderlineSliderProps> = ({ slides }) => {
  const sliderContentRef = useRef<HTMLUListElement>(null);
  const isMobile = useIsMobile();

  const {
    sliderRef,
    data: { activeSlideIndex, scrollSnaps },
  } = useSlider(!isMobile ? { align: "start" } : {});

  const {
    targetRef,
    data: { mousePosition, isMouseIn },
  } = useMouseTracker();

  // useEffect(() => {
  //   if (!sliderContentRef.current) return;

  //   gsap.from(sliderContentRef.current.children, {
  //     y: 20,
  //     duration: 1,
  //     autoAlpha: 0,
  //     stagger: 0.1,
  //     ease: Power1.easeOut,
  //   });
  // }, []);

  return (
    <div className={styles.underlineSlider}>
      <ul className={styles.bullets}>
        {scrollSnaps.map((_, index: number) => (
          <li key={index} className={`${styles.bulletWrapper} ${activeSlideIndex === index && styles.active}`}>
            <Icon className={styles.bulletIcon} name="Diamond" />
          </li>
        ))}
      </ul>
      <div className={styles.sliderWrapper} ref={targetRef}>
        <div className={styles.slider} ref={sliderRef}>
          <ul className={styles.sliderContent} ref={sliderContentRef}>
            {slides?.map((slide, index) => (
              <li key={`${slide.title} + ${index}`} className={styles.slide}>
                <ArticleCard cardContent={slide} isActiveCard={index === activeSlideIndex} displayCategory={false} />
              </li>
            ))}
          </ul>
        </div>
        {isMouseIn && !isMobile && <CustomCursor wrapperIcon="CustomCursor" startPosition={mousePosition} />}
      </div>
    </div>
  );
};
